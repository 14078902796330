<template lang="html">
  <section class="footer">
    <div class="container">
      <div class="text-center">
        <!-- <div class="m-2 mb-4"> 
          Technopark bureau 429<br/>
          Casablanca<br/>
          <span class="pi pi-phone"></span> <a href="tel:+212522215830">(+212) 5 22 21 58 30</a><br/>
          <span class="pi pi-mobile"></span> <a href="tel:+212661988460">(+212) 6 61 98 84 60</a><br/>
          <span class="pi pi-clock"></span> Lundi – Vendredi: 09:00 – 18:00<br/>
          Samedi – Dimanche: Fermé<br/>
        </div> -->
        <div class="m-2"> 
          Copyright 2022 © <a href="https://manageo.io/?utm_source=ice&utm_medium=ice&utm_campaign=ice">Manageo.io</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">


  export default  {
    props: [],
    data () {
      return {
      }
    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped lang="css">
.footer {
  margin: 30px;
}
.footer img {
  margin: 0px 5px 20px 0px;
  width: 30px;
}
</style>
