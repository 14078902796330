<template lang="html">
  <section class="header">
      <div class="container">
          <div class="row">
              <div class="col-md-6 text-left">
                  <a href="/" class="logo">
                      <img src="@/assets/img/logo.png"/>
                  </a>
              </div>
              <div class="col-md-6 text-right">
                  Logiciel de Facturation<br />
                  <span>Simple &amp; Efficace</span>
              </div>
          </div>
      </div>
  </section>
</template>

<script lang="js">

  export default  {
    props: [],
    mounted () {

    },
    data () {
      return {
      }
    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped lang="css">
.header {
  padding: 20px;
  background: #ffffff;
  margin: 10px auto;
  font-size: 20px;
  text-align: right;
  color: #444;
}
.logo img {
  width: 300px;
}
span {
  color: #0da3e2;
  font-size: 24px;
  font-weight: bold;
}
</style>
