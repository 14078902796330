import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import App from "./App.vue";

import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "primeicons/primeicons.css";

import "@/assets/css/style.css";
import "@/assets/css/style-responsive.css";

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuelidate);

Vue.prototype.baseURI = process.env.VUE_APP_BASE_URI;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

import Page from "./Page";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "default",
      path: "**",
      component: Page,
      meta: {
        title:
          "ICE au Maroc | Trouver l'ICE d'une entreprise au Maroc",
      },
    },
  ],
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
