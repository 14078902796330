<template lang="html">
  <section class="features grey-block">
    <div class="container">

      <h2>Qu'est ce que l'<strong>Identifiant Commun de l’Entreprise</strong> ?</h2>

    <p>L’ICE (Identifiant Commun de l’Entreprise) est un numéro qui identifie l’entreprise et 
    ses  succursales  de  façon  unique  et  uniforme  par  toutes  les  administrations,  il  vient 
    s’ajouter  aux  autres  identifiants  légaux  notamment  l’identifiant  fiscal,  le  numéro  de 
    registre de commerce et le numéro de CNSS. Toutefois, l’ICE ne remplace pas ces 
    identifiants qui restent obligatoires.</p> 

    <p>L’ICE permettra de : </p>

    <ul>
      <li>fluidifier la communication inter-administrations ; </li>
      <li>simplifier les procédures impliquant plusieurs administrations pour 
    l’accomplissement d’un même service administratif ; </li>
      <li>réduire les coûts et améliorer la fiabilité et la célérité des services 
    administratifs.  </li>
    </ul>
    
    <p>L’ICE concerne les entreprises personnes morales et leurs succursales ainsi que les 
    personnes physiques. </p>

    <p>L’ICE est un identifiant composé de 15 positions :</p>

    <ul>
      <li>l’entreprise (9 positions) ; </li>
      <li>ses établissements (4 positions) ; </li>
      <li>des caractères de contrôle (2 positions).</li>
    </ul>

    </div>
  </section>
</template>

<script lang="js">

  export default  {
    name: 'features',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="css">
.service-box .service-box-heading {
  padding: 12px 0;
  box-sizing: border-box;
}
.service-box .service-box-heading i.blue {
  border: 1px solid #0da3e2;
  color: #0da3e2;
  border-radius: 10%;
  font-size: 30px;
  padding: 5px;
}
.service-box .service-box-heading span {
  color: #444;
  display: inline-block;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 10px;
  margin-left: 10px;
}
</style>
