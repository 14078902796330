<template lang="html">
  <div class="page">
    <my-header></my-header>

    <Search />
    
    <Features />
    
    <Software />

    <Arguments/>

    <my-footer></my-footer>
  </div>
</template>

<script lang="js">

import MyHeader from "./components/Header";
import MyFooter from "./components/Footer";
import Search from "./components/Search";
import Arguments from "./components/Arguments";
import Features from "./components/Features";
import Software from "./components/Software";

  export default  {
    name: 'page',
    components: {
      MyHeader,
      Arguments,
      MyFooter,
      Search,
      Features,
      Software,
    },
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
}
</script>

<style scoped lang="css">

</style>
