<template lang="html">
<div class="wrapper">
    <div class="container">
    <div class="row">
      <div class="col-md-6">

        <h2>Recherche <strong>ICE MAROC</strong></h2>

        <p>Trouvez rapidement l'ICE d'une entreprise au Maroc avec notre outil de recherche par raison sociale.</p>

        <b-form @submit.prevent="search()" class="form-search">
          <b-form-group label="Raison Sociale *">
            <b-form-input
              placeholder="Raison Sociale"
              v-model="$v.request.term.$model"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="$v.request.term.$dirty && !$v.request.term.required"
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group>
            <b-button
              block
              type="submit"
              size="lg"
              variant="danger"
              class="mt-2"
            >
              <i class="pi pi-search"></i> Rechercher
            </b-button>
          </b-form-group>

          <div v-if="request.term">
            <div class="m-4" v-if="companies.length">
              {{ companies.length }} entreprises trouvées.
            </div>
            <div class="m-4" v-else>
              Aucune entreprise correspondant au mot clé.
            </div>
          </div>
        </b-form>

        <ul class="list-group mb-4" v-if="!company">
          <li
            class="list-group-item"
            v-for="element in companies"
            :key="element.id"
          >
            <a href="#" @click="details(element.id)" class="card-link">
              {{ element.denomination }} - {{ element.libelle }}
            </a>
          </li>
        </ul>

        <div class="card mb-4" v-if="company">
          <div class="card-body">
            <h5 class="card-title">
              <a href="#" class="card-link">{{ company.denomination }}</a>
            </h5>
            <p class="card-text">{{ company.activite }}</p>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              Statut : {{ company.workflowState }}
            </li>
            <li class="list-group-item">RC : {{ company.numeroRc }}</li>
            <li class="list-group-item">ICE : {{ company.numeroIce }}</li>
            <li class="list-group-item">Ville : {{ company.libelle }}</li>
            <li class="list-group-item">
              Forme Juridique : {{ company.formJuridique }}
            </li>
          </ul>
        </div>

      </div>
      <div class="col-md-6">
        
        <p class="">
          <strong>Découvrez Manageo.io, l'application de facturation tout-en-un !</strong>
        </p>

        <div class="">
          <ul>
          <li class="" v-for="(feature, index) in features" :key="index">
            {{ feature }}
          </li>
          </ul>
        
        </div>

        <div class="">
          <a class="btn btn-info btn-lg" target="_blank" href="https://lp.manageo.io/?utm_source=ice&utm_campaign=ice&utm_medium=ice">
            Demandez une démonstration gratuite
          </a>
        </div>
    </div>
  </div>
  </div>
</div>
</template>

<script lang="js">

import { required } from 'vuelidate/lib/validators'

import axios from 'axios' ;
import _ from 'lodash' ;

export default  {
  props: ["partnerid", "country"],
  data () {
    return {
      features : [] ,
      companies: [],
      company:null,
      request : {
        term : null,
      },
      formSuccess : false,
      formError: false,
      formStatus: false,
      requestForm : true,
    }
  },
  validations: {
    request : {
      term : { required },
    },
    // terms: { required, sameAs : 1},
  },
  mounted () {

    this.features = [
      "Gestion Complète des Clients et Fournisseurs",
      "Recherche Automatique des ICE",
      "Devis Personnalisés",
      "Gérez vos Factures en Toute Simplicité",
      "Suivi de Paiement ",
      "Rapports Complets et Compréhensibles",
      "Synchronisation et Accessibilité",
      "Sécurité et Confidentialité",
      "Support Client Réactif",
    ]
  },
  methods: {
    search() {
      let url = "https://www.directinfo.ma/directinfo-backend/api/queryDsl/search/"+decodeURIComponent(this.request.term);
      // console.log("url : "+ url)

      axios
      .get(url)
      .then(( response ) => {

        this.companies = response.data[0]
        this.company = null
        
        if (this.companies.length >= 20) {
          this.companies = _.slice(this.companies, 0, 20)
        }

        // _.map(this.companies, (o) => {
        //     company.url = "https://www.directinfo.ma/directinfo-backend/api/entreprise/"+company.id;
        // });

        // console.log(this.companies)

      })
      .catch(error => {
          console.log("error : ", error)
      });
    },
    details(id)
    {
      this.company = _.first(_.filter(this.companies, (o) => o.id == id))
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="css">
.btn.btn-lg.btn-block {
  font-size: 18px;
}
legend.col-form-label {
  text-align: right;
}
.custom-control {
  z-index: -1000;
}
p.intro {
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  margin: 20px 0px;
}
.company {
  margin-bottom: 15px;
}
.form-search {
  border:1px solid rgba(0, 0, 0, 0.125);
  border-radius:5px;
  background:#FFFFFF;
  padding:20px;
  margin-bottom:10px;
}
.wrapper {
  background:#e1eceb ;
  padding:30px 0px;
}
</style>
